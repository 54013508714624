"use client";

import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocalStorage } from "usehooks-ts";

const NavigationBlockerContext = createContext<
  [isBlocked: boolean, setBlocked: Dispatch<SetStateAction<boolean>>]
>([
  false,
  () => {
    // not empty
  },
]);

export function NavigationBlockerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // [isBlocked, setBlocked]
  const state = useState(false);
  return (
    <NavigationBlockerContext.Provider value={state}>
      {children}
    </NavigationBlockerContext.Provider>
  );
}

export function useIsBlocked() {
  const [isBlocked] = useContext(NavigationBlockerContext);
  return isBlocked;
}

export function Blocker() {
  const [isBlocked, setBlocked] = useContext(NavigationBlockerContext);
  useEffect(() => {
    setBlocked(() => {
      return true;
    });
    return () => {
      setBlocked(() => {
        return false;
      });
    };
  }, [isBlocked, setBlocked]);
  useEffect(() => {
    if (isBlocked) {
      const showModal = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = "";
      };

      window.addEventListener("beforeunload", showModal);
      return () => {
        window.removeEventListener("beforeunload", showModal);
      };
    }
  }, [isBlocked]);
  return null;
}

export function useLinkBlocker() {
  const [neverShowMeAgain, setNeverShowMeAgain] = useLocalStorage(
    "@nextgen/link-never-show-again",
    false,
    {
      initializeWithValue: false,
    },
  );
  return [neverShowMeAgain, setNeverShowMeAgain] as const;
}
